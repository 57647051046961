// Set Global URLs For Production and Testing Based on Release
export default {
    // ADMIN_API: window.location.href == 'https://hrms.digicollect.com/' ? "https://adminapis.digicollect.com" : "https://testadminapis.digicollect.com",
    // AUTH_URL: window.location.href == 'https://hrms.digicollect.com/' ? "https://admin.digicollect.com" : "https://testadmin.digicollect.com",
    // HRMS_URL: window.location.href == 'https://hrms.digicollect.com/' ? "https://hrms.digicollect.com" : "https://testhrms.digicollect.com",
    // HRMS_API: window.location.href == 'https://hrms.digicollect.com/' ? "https://hrmsapis.digicollect.com" : "https://testhrmsapis.digicollect.com",
    // AUTH_SERVICE: window.location.href == 'https://hrms.digicollect.com/' ? "https://adminapis.digicollect.com" : "https://testadminapis.digicollect.com",
    // POS_API: window.location.href == 'https://hrms.digicollect.com/' ? "https://posapismaster.digicollect.com" : "https://testposapis.digicollect.com",
    // SCM_API: window.location.href == 'https://hrms.digicollect.com/' ? "https://scmapis.digicollect.com" : "https://testscmapis.digicollect.com/manufacturing",
    // CRM_API: window.location.href == 'https://hrms.digicollect.com/' ? "https://crmapis.digicollect.com" : "https://testcrmapis.digicollect.com",
    // CRM_SALES_API: window.location.href == 'https://hrms.digicollect.com/' ? "https://crmsalesapis.digicollect.com" : "https://testcrmsalesapis.digicollect.com",
    // SCM_COMMON_API: window.location.href == 'https://hrms.digicollect.com/' ? "https://scmapis.digicollect.com/common" : "https://testscmapis.digicollect.com/common",
    // ADMIN_API: "https://adminapis.digicollect.com",
    // AUTH_URL: "https://admin.digicollect.com",
    // BOARDX_URL: "https://hrms.digicollect.com",
    // // BOARDX_URL: "https://testhrms.digicollect.com",
    // HRMS_API: "https://hrmsapis.digicollect.com",
    // // HRMS_API: "https://testhrmsapis.digicollect.com",
    // AUTH_SERVICE: "https://adminapis.digicollect.com",
    // // AUTH_SERVICE: "https://testadminapis.digicollect.com",
    // POS_API: "https://posapismaster.digicollect.com",
    // // POS_API: "https://testposapis.digicollect.com",
    // SCM_API: "https://scmapis.digicollect.com",
    // // SCM_API: "https://testscmapis.digicollect.com/manufacturing",
    // CRM_API: "https://crmapis.digicollect.com",
    // // CRM_API: "https://testcrmapis.digicollect.com",
    // CRM_SALES_API: "https://crmsalesapis.digicollect.com",
    // // CRM_SALES_API: "https://testcrmsalesapis.digicollect.com",
    // SCM_COMMON_API: "https://scmapis.digicollect.com/common",
    
    ADMIN_API: "https://testadminapis.digicollect.com",
    AUTH_URL: "https://testadmin.digicollect.com",
    BOARDX_URL: "https://testboardx.digicollect.com",
    BOARDX_API: "https://testboardxapis.digicollect.com",
    // BOARDX_URL: "https://testhrms.digicollect.com",
    HRMS_API: "https://testhrmsapis.digicollect.com",
    // HRMS_API: "https://testhrmsapis.digicollect.com",
    AUTH_SERVICE: "https://testadminapis.digicollect.com",
    // AUTH_SERVICE: "https://testadminapis.digicollect.com",
    POS_API: "https://testposapismaster.digicollect.com",
    // POS_API: "https://testposapis.digicollect.com",
    SCM_API: "https://testscmapis.digicollect.com",
    // SCM_API: "https://testscmapis.digicollect.com/manufacturing",
    CRM_API: "https://testcrmapis.digicollect.com",
    // CRM_API: "https://testcrmapis.digicollect.com",
    CRM_SALES_API: "https://testcrmsalesapis.digicollect.com",
    // CRM_SALES_API: "https://testcrmsalesapis.digicollect.com",
    SCM_COMMON_API: "https://testscmapis.digicollect.com/common",
};
