import Vue from 'vue'
import Router from 'vue-router'

const routerOptions = [

    { path: "/meeting/create_meeting", name: "Create Meeting", componentPath: 'CreateMeetings'},
    { path: "/meeting/preview_meeting", name: "Preview Meeting", componentPath: 'PreviewMeeting'},
    { path: "/", name: "Meeting List View", componentPath: 'Meeting/MeetingListView'},
    { path: "/shareholderList", name: "Meeting List View", componentPath: 'Meeting/MeetingListViewNew'},
    { path: "/meeting/boardMeetingListView", name: "Board Meeting List View", componentPath: 'Meeting/BoardMeetingListView'},
    { path: "/meeting/viewDetails/:id", name: "Board Meeting List View", componentPath: 'Meeting/ViewDetails'},
    { path: "/meeting/createMom/:id", name: "Create MOM", componentPath: 'Meeting/CreateMom'},
    // ==============  settings ====================//

    { path: "/settings", name: "Settings", componentPath: 'Settings/setting'},

    // ==============  Policies ====================//

    { path: "/policies", name: "Policies", componentPath: 'HelloWorld'},

    // ==============  Login and Verification ====================//

    { path: "/login", name: "Login", componentPath: 'Login/Login'},

    // { path: "/user/verify", name: "VerifyUser", componentPath: 'VerifyUser' },
    { path: "/verifyOtp", name: "OtpVerify", componentPath: 'OtpVerify' },

    // Access Denied
    // { path: '/subscription', name: 'AccessDenied', componentPath: 'AccessDenied'},





    // ***** Use other routes above this line only if you have a specific need *****


    // ================================================= Page Not Found ==============================================================
    {path:'/pagenotfound', name:'PageNotFound', componentPath: 'PageNotFound'},

    {path:'*', name:'PageNotFoundError', redirect: '/pagenotfound'}
]

const routes = routerOptions.map(route => {
    return {
        ...route,
        component: () => import(`@/components/${route.componentPath}.vue`)
    }
})

Vue.use(Router)

export default new Router({
    routes,
    base: process.env.BASE_URL,
    mode: 'history'
})