<template>
    <div class="app-digi" id="full-screen">
        <div class="horizontal-layout-1" v-if="loadingGif">
            <div class="d-flex justify-content-center align-items-center w-100 h-100">
                <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" style="margin: 0 auto;" />
            </div>
        </div>
        <div class="w-100 h-100" v-else>
            <div class="horizontal-layout-1" v-if="!showaccess">
                <div id="main" class="chrome" v-if="loggedIn && otpVerified && !register">
                    <div class="digitop">
                        <!-- <SharedNavbar /> -->
                        <navbar></navbar>
                    </div>
                    <div class="digicontainer">
                        <div class="dccontainer">
                            <section class="ps-container w-100 ps ps--theme_default">
                                <div class="bigcontainer" ref="containerDiv" id="containerDiv">
                                    <div class="content-block px-0">
                                        <div class="">
                                            <router-view/>
                                        </div>
                                    </div>
                                    <div class="p-0 position-relative">
                                        <foot></foot>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div class="horizontal-layout-1" v-else-if="register || !otpVerified">
                    <div id="main">
                        <router-view/>
                    </div>
                </div>
            </div>
            <div class="horizontal-layout-1" v-if="showaccess">
                <div id="main" class="chrome">
                    <div class="digitop">
                        <nav-top></nav-top>
                    </div>
                    <div class="digicontainer">
                        <div class="dccontainer">
                            <section class="ps-container w-100 ps ps--theme_default">
                                <div class="bigcontainer" ref="containerDiv" id="containerDiv">
                                    <div class="content-block px-0">
                                        <div class="">
                                            <access-denied></access-denied>
                                        </div>
                                    </div>
                                    <div class="p-0 position-relative">
                                        <footer-bottom></footer-bottom>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <switch-accessable-company modal_name="SwitchAccessableCompany" :error_msg="error_msg" v-if="switch_accessable" @DeleteSessionToken="DeleteSessionToken"></switch-accessable-company>
        <sweet-modal ref="success_modal" icon="success">{{success_msg}}</sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">{{warning_msg}}</sweet-modal>
    </div>
</template>
<script>
import axios from "axios";
import {EventBus} from './components/eventBus/nav-bar-event';
import global from "./components/global.js"
import { SweetModal } from 'sweet-modal-vue';
const Navbar = () => import('./components/Navbar');
const Foot = () => import('./components/Footer');
const AccessDenied = () => import('./components/AccessDenied');
const FooterBottom = () => import('./components/FooterBottom');
const NavTop = () => import('./components/NavTop');
import { HalfCircleSpinner } from "epic-spinners";
import SwitchAccessableCompany from './components/AlertModels/SwitchAccessableCompany';
export default {
    name: 'App',
    mixins:[],
    data(){
        return{
            fcmToken: "",
            success_msg: '',
            warning_msg: '',
            firebaseServerKey: "AAAAbYnlazc:APA91bHIjq3SWC3cEyTPmxipH3jg_HDG5DDksHOZbpCbi4o9ZxU6Jm259Fdf-URMogP5w_ebYvghDd7HqpPcNZud3FcSqo_RlAK4vK0tsoA0hGxQiktSFFUlrWtmHVf4hsv2hDSUQztZ",
            loadingGif: true,
            showaccess:false,
            status_code: null,
            loggedIn: false,
            otpVerified: false,
            registered: true,
            register: false,
            isTablet: false,
            isIpad: false,
            loadingApp: false,
            mobileView: false,
            warning_msg: '',
            stored_session_id: "",
            user_has: '',
            error_msg: '',
            switch_accessable: false
        }
    },
    components:{
        Navbar,
        Foot,
        AccessDenied,
        FooterBottom,
        NavTop,
        SweetModal,
        HalfCircleSpinner,
        SwitchAccessableCompany,
    },
    methods:{
        // Geting Logged In User and Set Token
        getLoggedInUser() {
            this.$http.get(global.AUTH_SERVICE + "/auth/get_loggedin_user?product="+'board-x').then(response => {
                if (response.data.status_id == 1) {
                    this.showAccess = false;
                    axios.defaults.headers.common.Authorization = this.$cookie.get("Token");
                    localStorage.setItem('token_socket', JSON.stringify(axios.defaults.headers.common.Authorization))
                    this.$store.commit('setSessionId', response.data.response.session_id)
                    this.$store.commit('setUserId', response.data.response.user_id)
                    this.stored_session_id = response.data.response.session_id;
                    this.user_has = response.data.response.user_has
                    if (response.data.response.is_verified) {
                        this.loggedIn = true;
                        this.$store.commit('setloggedInUserCompanyId', response.data.response.company_id);
                        this.otpVerified = true;
                        this.loadingGif = false;
                        let userDetails = response.data.response
                        userDetails['session_id'] = this.stored_session_id
                        userDetails['user_has'] = this.user_has
                        this.$store.commit('setLoggedInUser', userDetails);
                        this.$http.defaults.headers.common.SessionID = this.loggedInUser.session_id || '';
                        this.showaccess = false
                        this.registerInterceptors();
                    } else {
                        this.$router.push("/verifyOtp");
                    }
                } else {
                    this.loggedIn = false;
                    let routePath = this.$route.path.split("/")[1];
                    switch (routePath) {
                        case "register":
                        case "registerInvited":
                            break;
                        default:
                        window.location.href = global.BOARDX_URL;
                    }
                }
            }).catch(error => {
                // this.registerInterceptors()
                this.switch_accessable = true
                this.error_msg = error.response.data.message
                setTimeout(() => {
                    this.$modal.show('SwitchAccessableCompany')
                }, 500);
                // setTimeout(() => {
                //     this.$cookie.delete("Token");
                //     this.$cookie.delete('Token', {
                //         domain: 'digicollect.com'
                //     });
                //     this.$cookie.delete('Token', {
                //         domain: '.digicollect.com'
                //     });
                //     this.$cookie.delete('Token', {
                //         domain: '.digicollect.com',
                //         path: '/'
                //     });
                //     this.$cookie.delete('Token', {
                //         domain:document.location.hostname,
                //         path:document.location.pathname
                //     });
                //     this.$cookie.delete('Token', {
                //         domain:document.location.hostname,
                //     });
                //     this.$cookie.delete('Token', {
                //         path:document.location.pathname
                //     });
                //     this.$cookie.delete('Token', {
                //         path: '/'
                //     });
                //     var res = document.cookie;
                //     var multiple = res.split(";");
                //     for(var i = 0; i < multiple.length; i++) {
                //         var key = multiple[i].split("=");
                //         document.cookie = key[0]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
                //     }
                //     window.location.href = global.BOARDX_URL + "/login";
                // }, 10000);
            });
        },
        loadLoggedInUser() { },
        DeleteSessionToken(){
            this.$cookie.delete("Token");
            this.$cookie.delete('Token', {
                domain: 'digicollect.com'
            });
            this.$cookie.delete('Token', {
                domain: '.digicollect.com'
            });
            this.$cookie.delete('Token', {
                domain: '.digicollect.com',
                path: '/'
            });
            this.$cookie.delete('Token', {
                domain:document.location.hostname,
                path:document.location.pathname
            });
            this.$cookie.delete('Token', {
                domain:document.location.hostname,
            });
            this.$cookie.delete('Token', {
                path:document.location.pathname
            });
            this.$cookie.delete('Token', {
                path: '/'
            });
            var res = document.cookie;
            var multiple = res.split(";");
            for(var i = 0; i < multiple.length; i++) {
                var key = multiple[i].split("=");
                document.cookie = key[0]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
            }
            setTimeout(() => {
                window.location.href = global.BOARDX_URL + "/login?redirect_to=" + window.location.href;
            }, 500);
        },
        registerInterceptors() {
            this.$http.interceptors.request.use(
                config => {
                    if (this.$cookie.get('Token')) {
                        config.headers.Authorization = this.$cookie.get(
                            'Token'
                        )
                    }
                    return config
                },
                error => {
                    return Promise.reject(error)
                }
            )
            this.$http.interceptors.response.use(undefined, err => {
                if(err.response.status == '403') {
                    this.switch_accessable = true
                    this.error_msg = err.response.data.reason || ''
                    setTimeout(() => {
                        this.$modal.show('SwitchAccessableCompany')
                    }, 500);
                    // setTimeout(() => {
                    //     this.$cookie.delete('Token', {
                    //         domain: 'digicollect.com'
                    //     });
                    //     window.location.href = global.BOARDX_URL + "/login?redirect_to=" + window.location.href;
                    // }, 10000);
                } else {
                    return Promise.reject(err)
                }
            })
        },
        hideaccessdenial() {
            this.showaccess = false
            this.$cookie.delete('Token', {domain: 'digicollect.com'});
            window.location.href = global.AUTH_SERVICE+"?redirect_to="+global.BOARDX_URL;
        },
        load() {
            if(this.$cookie.get('Token',{domain:"digicollect.com"})) {
                this.$http.defaults.headers.common.Authorization = this.$cookie.get('Token');
                if (this.$route.path == "/subscription") {
                    this.$router.push("/");
                }
            } else {
                window.location.href = global.BOARDX_URL;
            }
            this.getLoggedInUser();
        },
        scrollToBottom(){
            this.$nextTick(function () {
                this.$refs.containerDiv.scrollTop = 10000;
            });
        },
        handleStyles () {
            // Red style to the body tag for the home page
            if (['/subscription'].includes(this.$route.path)) document.body.className = 'bg-white'
            // Pink style to the body tag for all other pages
            else if (document.body.classList.contains('bg-white')) document.body.className = 'bg-white'
        },
        scrollToTop() {
            this.$nextTick(function(){
                this.$refs.containerDiv.scrollTop = 0;
                window.scrollTo(0,0);
            })
        },
        scrollToTopPhone() {
            this.$nextTick(function(){
                this.$refs.containerDiv.scrollTop = 800;
                window.scrollTo(800,0);
            })
        }
    },
    computed: {
        session_id() {
            return this.$store.getters.session_id
        },
        user_id() {
            return this.$store.getters.user_id
        },
        loggedInUser() {
            return this.$store.getters.loggedInUser
        },
        userSettings() {
            return this.$store.state.store.userSettings
        },
        getGeneralSettings() {
            return this.$store.state.store.getGeneralSettings
        },
        crmDetails() {
            return this.$store.state.store.crmDetails
        },
        fcmDetails() {
            return this.$store.getters.fcmDetails;
        }
    },
    watch: {
        '$route' (to,from) {
            this.handleStyles()
            if (to.path != "/register" && from.path == "/login" && !this.$cookie.get("Token")) {
                // this.$router.push("/login").catch((err)=>{
                //     if(this.$route.path == '/login') {
                //     } else {
                //         this.$router.push("/login")
                //     }
                // });
                if (window.location.pathname != '/') {
                    if(this.$route.query.hasOwnProperty('redirect_to')) {
                    } else {
                        this.$router.push("/login?redirect_to=" + window.location.href).catch((errr) => {
                            this.$router.push("/login?redirect_to=" + window.location.href)
                        });
                    }
                } else {
                    this.$router.push("/login").catch((errr) => {
                        this.$router.push("/login")
                    });
                }
            }
        },
        "$route.path": function (path) {
            if (this.$cookie.get("Token") && path == '/verifyOtp') {
                this.otpVerified ? this.$router.push("/") : this.$router.push("/verifyOtp");
            }
            if ( this.$cookie.get("Token") && path == "/subscription") {
                this.$router.push("/");
            }
        },
        'activeRefresh': function (val) {
            if(val) {
                this.load();
            }
        },
        isIpad(){
            if(isTablet){
                this.isIpad = true
            }
            else{
                this.isIpad = false
            }
        }
    },
    async mounted() {
        setTimeout(() => {
            if (this.$cookie.get("Token")) {
                if (this.$route.path == "/login" || this.$route.path == "/subscription") {
                    this.$router.push("/").catch((err)=>{
                        if(this.$route.path == '/') {
                        } else {
                            this.$router.push("/")
                        }
                    });
                }
                this.load()
            } else {
                // if(this.$route.path.startsWith('/login')) {
                //     this.loadingGif = false;
                //     this.register = true;
                // } else {
                //     this.loadingGif = false;
                //     this.register = true;
                //     this.$router.push("/login").catch((err)=>{
                //         if(this.$route.path == '/login') {
                //         } else {
                //             this.$router.push("/login")
                //         }
                //     });
                // }
                this.loadingGif = false;
                this.register = true;
                if (window.location.pathname != '/') {
                    if(this.$route.query.hasOwnProperty('redirect_to')) {} 
                    else {
                        this.$router.push("/login" ).catch((errr) => {
                            this.$router.push("/login")
                        });
                    }
                } 
                else {
                    this.$router.push("/login").catch((errr) => {
                        this.$router.push("/login")
                    });
                }
            }
        }, 500);
        let browserLang = navigator.language.split('-')[0]
        localStorage.setItem('language', browserLang)
        localStorage.getItem('language');
        this.handleStyles()
        EventBus.$on("scrollToTop", () => this.scrollToTop());
        EventBus.$on("scrollToBottom", () => this.scrollToBottom());
        EventBus.$on("scrollToTopPhone", () => this.scrollToTopPhone());
        const userAgent = navigator.userAgent.toLowerCase();
        this.isTablet = /(iphone|ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
        EventBus.$on('UnSubscribe', () => {
            this.unSubscribeTokenToTopic();
        })
    },
    destroyed(){
        EventBus.$off('scrollToTop')
        EventBus.$off('scrollToBottom')
        EventBus.$off('scrollToTopPhone')
    },
}
</script>
<style scoped>
.ps {
  position: relative;
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}
.ps {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}
.ps {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}
.ps-container {
  position: relative;
}
.access-denied-alert {
    position: relative !important;
    top: 30% !important;
    justify-content: center !important;
    display: flex !important;
    font-size: 18px;
    text-align: center;
    color: #0d84f2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 220px;
    position: relative;
    background: #ffffff;
    -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=3, MakeShadow=true, ShadowOpacity=0.30);
    -ms-filter: "progid:DXImageTransform.Microsoft.Blur(PixelRadius=3,MakeShadow=true,ShadowOpacity=0.30)";
    zoom: 1;
    border-radius: 18px;
    width: 50%;
    margin: 0 auto;
}

.access-denied-alert span {
    justify-content: center !important;
    display: block !important;
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
}
.access-denied-alert span img {
    width: 40px;
}
.overflow-hidden{
    overflow: hidden !important;
}
.transform-screen{
    transform: rotate(90deg);
}
#full-screen:fullscreen {
    width: 100%;
    height: 100%;
}

/* prefix for Chrome & Safari */
#full-screen:-webkit-full-screen {
    width: 100%;
    height: 100%;
}

.fw-600{
    font-weight: 600 !important;
}
</style>
